<template>
  <div class="legal">
    <heade></heade>
    <div class="cen_wrap" :style="{
      backgroundImage: 'url(' + require('@/assets/' + backgroud) + ')',
    }">
      <div class="cen_box nice-scroll">
        <div class="articleList">
          <router-link v-for="(item, index) in articleList" :key="index" :to="{
            path: '/article/detail',
            query: { id: item.id },
          }" class="article_item">
            <p class="title">{{ item.name }}</p>
            <p class="description" v-html="item.description"></p>
          </router-link>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import "@css/ico.css";
import "@css/foot.css";
import $ from "jquery";
import { scroll } from "@utils/scroll";
import heade from "@components/heade.vue";
import foot from "@components/foot.vue";
import { getArticleList } from "@api/public";
export default {
  components: {
    heade,
    foot,
  },
  props: {},
  data() {
    return {
      articleList: {},
      queryWhere: {},
      backgroud: "",
    };
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
  mounted: function () {
    let that = this;
    getArticleList(that.queryWhere).then((res) => {
      that.$set(that, "articleList", res.data.list);
    });
    scroll($(".nice-scroll"));
  },
  created: function () {
    this.backgroud = this.$route.meta.background;
    document.title = this.$route.meta.title;
    this.queryWhere.article_category_id = this.$route.meta.article_category_id;
  },
  methods: {},
  destroyed: function () {
    $(".nice-scroll").getNiceScroll().hide();
    $(".nicescroll-cursors").hide();
    $(".nicescroll-rails").remove();
  },
};
</script>

<style>
.legal .cen_wrap {
  width: 100%;
  height: 1293px;
  background-size: cover;
  background-position: center 0;
}

.legal .cen_box {
  width: 1324px;
  height: 929px;
  position: relative;
  top: 420px;
  left: 285px;
  box-sizing: border-box;
  padding: 20px 100px;
}

.cen_box>.cen {
  width: 100%;
  height: 100%;
}

.cen_box>.cen::-webkit-scrollbar {
  width: 0;
}

.cen_box p {
  font-size: 16px;
  letter-spacing: 3px;
}

.cen_box .articleList {
  position: relative;
  /* border: 1px solid blue; */
}

.cen_box .articleList .article_item {
  display: block;
}

.article_item+.article_item {
  margin-top: 20px;
  border-top: 1px dashed gray;
  padding-top: 20px;
}

.cen_box .articleList .article_item .title {
  font-weight: bold;
  font-size: 20px;
  font-family: "楷体";
  margin-bottom: 10px;
  letter-spacing: 4px;
}

.cen_box .articleList .article_item .description {
  color: #555;
}
</style>